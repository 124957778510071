<template>
  <div>
    <v-card>
      <v-card-text class="pt-5">
        <h2 class="text-h5 mb-5">
          Переключить себя на ветку
        </h2>
        <v-row>
          <!-- КОД -->
          <v-col :cols="6">
            <v-text-field
              v-model="form.machineID"
              label="Machine ID"
              dense
              outlined
              hide-details
              :spellcheck="false"
            />
          </v-col>
          <v-col :cols="6">
            <v-autocomplete
              v-model="form.branch"
              label="Ветка"
              :loading="loadingBranches"
              :items="branches"
              :search-input.sync="searchInput"
              dense
              outlined
              hide-details
              item-text="name"
              return-object
            />
          </v-col>
          <v-col class="shrink py-0">
            <v-btn
              :disabled="!form.branch"
              :loading="loadingBtn"
              @click="saveMachine"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Branches',

  data() {
    return {
      menu: false,
      loadingBtn: false,
      loadingBranches: false,
      searchInput: null,
      branches: [],
      form: {
        machineID: '',
        branch: '',
      },
    };
  },
  watch: {
    searchInput(value) {
      if (value.length > 1) {
        this.search(value);
      }
    },
  },
  created() {
    this.search = this.$_.debounce(this.search, 300);
  },

  methods: {
    async search(q) {
      this.loadingBranches = true;
      const response = await this.api.get(`/admin/branch/list?search=${q}`);
      this.branches = response.data.data;
      this.loadingBranches = false;
    },
    async saveMachine() {
      this.loadingBtn = true;
      try {
        const response = await this.api.post('/admin/branch/store-access', {
          machineId: this.form.machineID,
          branchId: this.form.branch.id,
        });
        if (response.data.success) {
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } finally {
        this.loadingBtn = false;
        this.form.machineID = '';
        this.form.branch = [];
        this.branches = [];
      }
    },
  },

};
</script>
